<template>
<div>
  <Header/>
  <b-container 
    style="max-width:95%; padding-top:70px;"
  >
    <div >
    <b-row>
      <b-col>
        <h1>施設一覧</h1>
      </b-col>
      <b-col 
        style="text-align:right;"
      >
        <b-link 
          class="btn btn-success" 
          to="/facilityform"
        >
          施設新規登録
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col 
        class="col-md-5"
      >
        <b-button 
          class="btn btn-success " 
          @click="searchSubmit"
        >
          絞り込み検索
        </b-button>
      </b-col>
      <b-row >
        <b-row>
          <b-col 
            md="7" 
            class="text-center"
          >
            <b>
              表示件数：{{ totalFacility }}件中{{ displayFacility }}件
            </b>
              <paginate
                :page-count="getPaginateCount"
                :prev-text="'<'"
                :next-text="'>'"
                :click-handler="paginateClickCallback"
                :container-class="'pagination justify-content-center'"
                :page-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :first-last-button="true"
                :first-button-text="'<<'"
                :last-button-text="'>>'"
                :force-page="currentPage"
              >
            </paginate>
          </b-col>
          <b-col 
            md="3"
          >
            <select 
              class="form-select" 
              name="displayLimits" 
              v-model="display"
            >
              <option 
                v-for="displayLimit in displayLimits" 
                :key="'displayLimit-' +displayLimit.id"
                :value="displayLimit.value"
              >
                {{ displayLimit.text }}
              </option>
            </select>
            
          </b-col>
          <b-col>
            <b-button 
              variant="primary"
              @click="changeLimit"
            >
              表示件数変更
            </b-button>
          </b-col>
        </b-row>
      </b-row>
      <span 
        class="text-danger" 
        v-if="message.search !== ''"
      >
        {{ message.search }}
      </span>
    </b-row>
    <FacilitySearch
      :search="search" 
      @searchList="searchList"
    />
  </div>

    <div 
      class="table-responsive " 
    >
      <table 
        class="table table_sticky table-bordered" 
      >
        <thead 
          class="table-secondary" 
        >
          <tr>
            <th 
              scope="col" 
              style="width:10%"
            >
              <b-button 
                class="btn btn-success text-nowrap" 
                @click="updateNextShootTime()"
              >
                撮影日更新
              </b-button>
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              撮影日付
              <br>次回撮影予定日<br>
                <!-- <button
                  @click="sortFacility('nextShootingAsc')"
                  type="button" 
                  :class="sort[0].nextShootingAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                > -->
                  <b-icon 
                    :variant="sort[0].nextShootingAsc ? 'primary' : 'secondary'"
                    @click="sortFacility('nextShootingAsc')"
                    :icon="arrowUpIcon"
                    scale="2"
                    class="btn_link" 
                  ></b-icon>
                <!-- </button> -->
                <!-- <button 
                  @click="sortFacility('nextShootingDesc')"
                  type="button" 
                  :class="sort[1].nextShootingDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
                > -->
                  <b-icon 
                    :variant="sort[1].nextShootingDesc ? 'primary' : 'secondary'"
                    class="ms-3 btn_link"
                    @click="sortFacility('nextShootingDesc')"
                    :icon="arrowDownIcon"
                    scale="2"  
                  ></b-icon>
                <!-- </button> -->
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              撮影日からの経過日数<br>
              <!-- <button 
                @click="sortFacility('datediffAsc')"
                type="button" 
                :class="sort[2].datediffAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[2].datediffAsc ? 'primary' : 'secondary'"
                  @click="sortFacility('datediffAsc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"  
                ></b-icon>
              <!-- </button> -->
              <!-- <button 
                @click="sortFacility('datediffDesc')"
                type="button" 
                :class="sort[3].datediffDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[3].datediffDesc ? 'primary' : 'secondary'"
                  class="ms-3 btn_link"
                  @click="sortFacility('datediffDesc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              <!-- </button> -->
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設名
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設詳細名
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設住所
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設区分<br>
              <!-- <button 
                @click="sortFacility('classAsc')"
                type="button" 
                :class="sort[4].classAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[4].classAsc ? 'primary' : 'secondary'"
                  @click="sortFacility('classAsc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link" 
                ></b-icon>
              <!-- </button > -->
              <!-- <button
                @click="sortFacility('classDesc')"
                type="button" 
                :class="sort[5].classDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[5].classDesc ? 'primary' : 'secondary'"
                  class="ms-3 btn_link"
                  @click="sortFacility('classDesc')"
                  :icon="arrowDownIcon"
                  scale="2" 
                ></b-icon>
              <!-- </button> -->
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              状態<br>
              <!-- <button 
                @click="sortFacility('approvalAsc')"
                type="button" 
                :class="sort[6].approvalAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[6].approvalAsc ? 'primary' : 'secondary'"
                  @click="sortFacility('approvalAsc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"  
                ></b-icon>
              <!-- </button> -->
              <!-- <button 
                @click="sortFacility('approvalDesc')"
                type="button" 
                :class="sort[7].approvalDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[7].approvalDesc ? 'primary' : 'secondary'"
                  class="ms-3 btn_link"
                  @click="sortFacility('approvalDesc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              <!-- </button> -->
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              撮影者<br>
              <!-- <button 
                @click="sortFacility('photographerAsc')"
                type="button" 
                :class="sort[8].photographerAsc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[8].photographerAsc ? 'primary' : 'secondary'"
                  @click="sortFacility('photographerAsc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"  
                ></b-icon>
              <!-- </button> -->
              <!-- <button
                @click="sortFacility('photographerDesc')"
                type="button" 
                :class="sort[9].photographerDesc ? 'btn btn-primary btn-sm' : 'btn btn-secondary btn-sm'"
              > -->
                <b-icon 
                  :variant="sort[9].photographerDesc ? 'primary' : 'secondary'"
                  class="ms-3 btn_link"
                  @click="sortFacility('photographerDesc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              <!-- </button> -->
            </th>
            <th 
              scope="col" 
              style="width:10%"
            >
              施設写真
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(facilityData, index) in facilityDatas"
            :key="index"
          >
            <th 
              scope="row"
            >
              {{ index + 1 }}<br>
              <input 
                v-if="role === '0' && facilityData.vcStorage !== noImage||
                userName === facilityData.vcUserLName && facilityData.vcStorage !== noImage"
                name="checkUpdateNextShoot"  
                class="form-check-input" 
                type="checkbox"
                :value="facilityData.inPhotoID"
                v-model="checkUpdate"
              >
            </th>
            <td>
              {{ facilityData.dtPhotoDate }}
              <br>
              <b-form-input 
                type="date" 
                v-model="facilityData.dtNextShootingDate"
              />
            </td>
            <td 
              class="text-center"
            >
              {{ facilityData.datediff }}日
            </td>
            <td>
              <router-link 
                :to="{name:'FacilityDetail',
                params : {id: facilityData.inFacilityID, detailID: facilityData.inFacilityDetailsID}}"
              >
                {{ facilityData.vcFacilityName }}
              </router-link>
            </td>
            <td>
              {{ facilityData.vcFacilityDetailName }}
            </td>
            <td>
              {{ facilityData.vcFacilityAdd }}
            </td>
            <td 
              v-if="facilityData.vcMiddleClassName === '' ||
              facilityData.vcMiddleClassName === null"
            >
              {{ facilityData.vcClassificationName }}
            </td>
            <td 
              v-else
            >
              {{ facilityData.vcMiddleClassName }}
            </td>
            <td>
              {{ facilityData.inApproval }}
            </td>
            <td>
              {{ facilityData.vcUserLName }}
            </td>
            <td >
              <b-img 
                :class="{'img_link': facilityData.vcStorage !== '/static/images/noimage.jpg'}"
                @click="showImg(facilityData.vcFacilityName,facilityData.vcFacilityDetailName,facilityData.vcStorage)"
                style="width:100px;height:100px;" 
                :src="facilityData.vcStorage"
              ></b-img>
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>
  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import FacilitySearch from '../../components/FacilitySearchModal.vue'
import { Apimixin } from '../../mixins/api'


export default {
  title: '施設情報一覧',
  components: {
    Header,
    FacilitySearch
  },
  mixins:[Apimixin],
  data() {
    return {
      arrowUpIcon:'file-arrow-up-fill',
      arrowDownIcon:'file-arrow-down-fill',
      search: {
        facilityType: '',
        middleFacilityType: '',
        photoGrapherID:'',
        keyword: '',
        searchTo:'',
        searchFrom:'',
        address:"",
        sort:'',
        unregistered:''
      },
      sessionSearchData:'',
      display:'',
      role:'',
      userName:'',
      checkUpdate:[],
      nextShootDate:[],
      currentPage : 1, 
      limit: 10,
      offset:0,
      isLoading:false,
      upIcon:'chevron-up',
      downIcon:'chevron-down',
      searchOption:false,
      pageActive:false,
      sort: [
        { nextShootingAsc:'' }, 
        { nextShootingDesc:'' },
        { datediffAsc:'' },
        { datediffDesc: ''},
        { classAsc: ''},
        { classDesc:'' },
        { approvalAsc:'' },
        { approvalDesc:'' },
        { photographerAsc:'' },
        { photographerDesc:'' }
      ],
    }
  },
  created() {
    //施設一覧全件取得
    this.sessionSearchData = JSON.parse(sessionStorage.getItem('searchData'))
    if(this.sessionSearchData !== null) {
      if(this.sessionSearchData.facilityType !== '') {
        this.search.facilityType = this.sessionSearchData.facilityType
      }
      if(this.sessionSearchData.middleFacilityType !== '') {
        this.search.middleFacilityType = this.sessionSearchData.middleFacilityType
      }
      if(this.sessionSearchData.photoGrapherID !== '') {
        this.search.photoGrapherID = this.sessionSearchData.photoGrapherID
      }
      if(this.sessionSearchData.searchFrom !== '') {
        this.search.searchFrom = this.sessionSearchData.searchFrom
      }
      if(this.sessionSearchData.searchTo !== '') {
        this.search.searchTo = this.sessionSearchData.searchTo
      }
      if(this.sessionSearchData.unregistered !== '') {
        this.search.unregistered = this.sessionSearchData.unregistered
      }
      if(this.sessionSearchData.keyword !== '') {
        this.search.keyword = this.sessionSearchData.keyword
      }
      if(this.sessionSearchData.address !== '') {
        this.search.address = this.sessionSearchData.address
      }
      
      this.searchOption = true
      this.currentPage = Number(sessionStorage.getItem('currentPage'))
      this.display = Number(sessionStorage.getItem('limit'))
      this.limit = Number(sessionStorage.getItem('limit'))
      let offset = Number(sessionStorage.getItem('offset'))
      this.searchFacility(offset,this.limit,this.search)
    } else if(sessionStorage.getItem('currentPage') !== null){
      this.display = Number(sessionStorage.getItem('limit'))
      this.limit = Number(sessionStorage.getItem('limit'))
      this.currentPage = Number(sessionStorage.getItem('currentPage'))
      this.getLists
    } else {
      this.display = this.limit
      sessionStorage.setItem('currentPage', this.currentPage)
      this.getLists
    }
  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userName = sessionStorage.getItem('userName')
  },
  
  methods:{
    searchSubmit() {
      this.$bvModal.show('FacilitySearchModal')
    },
    //施設絞り込み
    searchList() {
      this.$bvModal.hide('FacilitySearchModal')
      this.currentPage = 1
      sessionStorage.setItem('currentPage', this.currentPage)
      let offset = 0
      sessionStorage.setItem('offset', offset)
      this.searchOption = true
      return this.searchFacility(offset,this.limit,this.search)
    },
    //施設並び替え
    sortFacility(sort) {
      this.search.sort = sort
      this.sort.forEach(elm => {
        Object.keys(elm).forEach(key => {
          if(key === sort) {
            elm[key] = true
          } else {
            elm[key] = ''
          }
        })
      })
      this.searchOption = true
      let offset = (this.currentPage - 1) * this.limit;
      return this.searchFacility(offset,this.limit,this.search)
    },
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('currentPage', JSON.stringify(this.currentPage))
      this.getLists;
    },
    //写真拡大表示
    showImg(vcFacilityName,vcFacilityDetailName,vcStorage){
      if(vcStorage !== this.noImage) {
        vcStorage =vcStorage.replace('/resizephoto/','/photo/')
        const h = this.$createElement
        const titleVNode = h('div', { domProps: { innerHTML: vcFacilityName+" "+vcFacilityDetailName } })
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('b-img', {
            props: {
              src: vcStorage,
              thumbnail: true,
              center: true,
              fluid: true,
            }
          })
        ])
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'xl',
          centered: true, size: 'xl'
        })
      }
    },

    //次回撮影日更新
    updateNextShootTime() {
      if(this.checkUpdate.length !== 0) {
        let self =this
        var nextShootingDate = []
        self.checkUpdate.filter(function(value) {
          for(let i = 0;i < self.facilityDatas.length;i++) {
            if(self.facilityDatas[i].inPhotoID === value) {
              nextShootingDate.push(self.facilityDatas[i])
            }
          }
        })
          this.$swal({
            title: '次回撮影日更新',
            html: `次回撮影予定日を更新しますか？`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '確定',
            cancelButtonText:'キャンセル'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: '処理中',
                html: '処理終了まで画面はそのままにしてください。<b-spinner label="Spinning"></b-spinner>', 
                allowOutsideClick : false , 
                showConfirmButton: false, 
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                }
              }); 
              this.updatePhotoData(nextShootingDate)
            }
          })
        
      } else {
        this.$swal({
          icon: 'error',
          title:'施設をチェックボックスで選択してください。',
          confirmButtonText:"閉じる"
        })
      }
    },
    //表示件数変更
    changeLimit() {
      this.limit = this.display
      sessionStorage.setItem('limit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getLists
    }

  },
  computed: {
    getLists() {
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('offset', offset)
      sessionStorage.setItem('limit', this.limit)
      if(this.searchOption) {
        return this.searchFacility(offset,this.limit,this.search)
      } else {
        return this.getFacilityList(offset, this.limit);
      }
      
    },
    //総ページ数の計算
    getPaginateCount() {
      return Math.ceil(this.totalFacility / this.limit);
    }
  },
  watch:{
    'currentPage':function() {
      sessionStorage.setItem('currentPage', this.currentPage)
    }
  }
}
</script>
<style scoped>
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(65vh);
    
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.btn_link {
  cursor: pointer;
}
</style>
