<template>
  <b-modal
  id="FacilitySearchModal"
  title="絞り込み検索"
  size="lg"
  hide-header-close
  hide-footer

  >
    <b-row>
      <b-col >
        <b-row>
          <h5>施設区分検索</h5>
          <b-row>
            <h6>大区分：</h6>
            <b-col md="10">
              <select 
                class="form-select" 
                name="facilityType"
                v-model="search.facilityType"
              >
                <option value="">選択してください</option>
                <option 
                  v-for="facilityType in facilityTypes"
                  :key="'facilityType-' +facilityType.inClassificationID"
                  :value="facilityType.inClassificationID"
                >
                  {{ facilityType.vcClassificationName }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row 
            v-if="middleFacility"
            class="mt-2"
          >
            <h6>中区分：</h6>
            <b-col md="10">
              <select 
                class="form-select" 
                name="facilityType"
                v-model="search.middleFacilityType"
              >
                <option value="">選択してください</option>
                <option 
                  v-for="middleType in selectMiddleTypes" 
                  :key="'middleFacilityType-' + middleType.inClassificationID"
                  :value="middleType.inClassificationID"
                >
                  {{ middleType.vcMiddleClassName }}
                </option>
              </select>
            </b-col>
          </b-row>
        </b-row>
        <b-row>
          <h5>撮影者検索</h5>
          <b-col md="10">
            <select 
              class="form-select" 
              name="photoGrapher" 
              v-model="search.photoGrapherID"
            >
              <option value="">選択してください</option>
              <option 
                v-for="user in usersData" 
                :key="'user' + user.inUserID"
                :value="user.inUserID"
              >
                {{ user.vcUserLName }}
              </option>
            </select>
          </b-col>
        </b-row>
        <b-row>
          <h5>施設名検索</h5>
          <b-col md="10">
            <b-form-input 
              type="text"
              v-model="search.keyword"
              placeholder="施設名を入力してください"
            />
          </b-col>
        </b-row>
        <b-row>
          <h5>住所検索</h5>
          <b-col md="10">
            <b-form-input 
              type="text"
              v-model="search.address"
              placeholder="住所を入力してください"
              />
          </b-col>
        </b-row>
        <b-row>
          <h5>次回撮影日検索</h5>
          <b-col md="10">
            <small>From</small>
            <b-form-input 
              type="date"   
              v-model="search.searchFrom"
            /> 
          </b-col>
          <b-col md="10">
            <small>To</small>
            <b-form-input 
              type="date" 
              v-model="search.searchTo"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10">
            <label for="unregisteredCheck">
              <h5>未撮影施設を含む：</h5>
            </label>
            <input 
              name="checkUnregistered"  
              class="form-check-input" 
              type="checkbox"
              id="unregisteredCheck"
              v-model="search.unregistered"
              >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col style="text-align:center;" class="mt-2">
        <b-button 
          size="lg"
          style="width:50%" 
          variant="primary" 
          @click="filtering"
        >
          絞り込み
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { Apimixin } from '../mixins/api'
export default {
  mixins:[Apimixin],
  props:{
    search:Object
  },
  data() {
    return {
      selectMiddleTypes:[],
      middleFacility:false,
      sessionSearchData:'',
      sessionMiddleFacility:''
    }
  },
  created() {
    //施設区分情報取得
    this.getFacilityType();
    //ユーザー情報取得
    this.getUsers(sessionStorage.getItem('userId'))
  },
  mounted() {
    let searchData = JSON.parse(sessionStorage.getItem('searchData'))
    if(searchData !== null && searchData.middleFacilityType !== '') {
      this.sessionMiddleFacility = searchData.middleFacilityType
    }
  },
  methods:{
    filtering(){
      sessionStorage.setItem('searchData', JSON.stringify(this.search))
      this.$emit('searchList')
    },
    //中区分取得
    getMiddleFacility() {
      for(let i = 0;i < this.middleFacilityTypes.length; i++) {
        if(this.middleFacilityTypes[i].inParentClassID === this.search.facilityType) {
          this.middleFacility = true
          this.selectMiddleTypes.push(this.middleFacilityTypes[i])
          
        }
      }
    }
  },
  watch:{
    //中区分アリの場合中区分取得処理へ
    'search.facilityType':function(){
      this.middleFacility = false;
      this.selectMiddleTypes = [];
      this.search.middleFacilityType = "";
      this.getMiddleFacility();
    },
    'middleFacilityTypes':function() {
      if(this.sessionMiddleFacility !== '') {
        this.getMiddleFacility();
      }
      
    }
  }
  
}
</script>



